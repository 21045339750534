import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ColorButton = styled(Button)(({ theme }) => ({
    color: "#fff",
    backgroundColor: "#2E65F3",
    textTransform: "none",
    "&:hover": {
        backgroundColor: "#2979ff",
    },
}));

export default function BlueButton({ text, onClick }) {
    return (
        <ColorButton variant="contained" onClick={onClick} sx={{ m: 1 }}>
            {text}
        </ColorButton>
    );
}
