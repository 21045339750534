import "./App.css";
import Container from "@mui/material/Container";
import SignIn from "./pages/sign-in/SignIn";
import Form from "./pages/form/Form";
import Navbar from "./components/navbar/Navbar";
import { Routes, Route } from "react-router-dom";
import StoreForm from "./pages/store-form/StoreForm";
import Visit from "./pages/visit/Visit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoaderContext } from "./context/LoaderContext";
import { useContext } from "react";
import Loader from "./components/loader/Loader";

function App() {
    const { showLoader } = useContext(LoaderContext);
    return (
        <>
            <Container component="main" maxWidth="xs" sx={{ height: "100vh", overflow: "scroll" }}>
                <ToastContainer />
                {showLoader && <Loader />}
                <Routes>
                    <Route path="/" element={<SignIn />} />
                    <Route path="/form" element={<Navbar />}>
                        <Route path="create" element={<Form />} />
                        <Route path="create/:store" element={<StoreForm />} />
                        <Route path="visit/:store" element={<Visit />} />
                    </Route>
                </Routes>
            </Container>
        </>
    );
}

export default App;
