import { Button } from "@mui/material";
import React from "react";
import { UserAuth } from "../../context/AuthContext";

const Signout = () => {
    const { logOut } = UserAuth();

    const handleSignout = () => {
        logOut();
    };
    return (
        <Button variant="outlined" color="error" onClick={handleSignout}>
            SignOut
        </Button>
    );
};

export default Signout;
