import styled from "@emotion/styled/macro";

export const Li = styled.li`
    list-style-type: none;
    padding: 8px 4px;
    box-sizing: border-box;
    background-color: ${(props) => (props.id ? "#fff" : "#2D68FE")};
    color: ${(props) => (props.id ? "#1E293B" : "#fff")};
    border-radius: 3px;
`;
