import React, { useEffect, useState } from "react";
import { AppBar, Box, CssBaseline, IconButton, Toolbar, Typography, makeStyles } from "@mui/material";
import { Outlet } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Signout from "../sign-out/Signout";
import AreaCode from "../area-code/AreaCode";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import { UserAuth } from "../../context/AuthContext";

const Navbar = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Box maxWidth="xs" sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar component="nav" sx={{ background: "#fff", p: 1 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        {/* <IconButton edge="start" onClick={() => setOpen(!open)} color="inherit" aria-label="open drawer">
                            <MenuIcon />
                        </IconButton> */}
                        {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { sm: "block" }, color: "#FFDE2C" }}>
                            Home
                        </Typography> */}
                        <AreaCode />
                        <Signout />

                        {/* <Box sx={{ display: { sm: "block" }, marginLeft: "20px" }}></Box> */}
                    </Box>
                </AppBar>
            </Box>
            <Outlet />
        </>
    );
};

export default Navbar;
