export const store_names = [
    {
        name: "store 1",
        value: "store 1",
    },
    {
        name: "store 2",
        value: "store 2",
    },
    {
        name: "store 3",
        value: "store 3",
    },
    {
        name: "store 4",
        value: "store 4",
    },
    {
        name: "store 5",
        value: "store 5",
    },
];

export const boolean_values = [
    {
        name: "Yes",
        value: true,
    },
    {
        name: "No",
        value: false,
    },
];
export const ratings = [
    {
        name: "Excited",
        value: "Excited",
    },
    {
        name: "Good",
        value: "Good",
    },
    {
        name: "Neutral",
        value: "Neutral",
    },
    {
        name: "Bad",
        value: "Bad",
    },
];

export const follow_up_type = [
    {
        name: "Call",
        value: "call",
    },
    {
        name: "In Person",
        value: "in-person",
    },
];

export const EXISTING_SOFTWARES = [
    "Marg",
    "Busy",
    "Vyapar",
    "MyBillBook",
    "SWIL",
    "GoFrugal",
    "Tally",
    "Vasy",
    "Hostbooks",
    "PagarBook",
    "Khatabook",
    "OkCredit",
    "Dukaan",
    "Create New",
];

export const ONLINE_SOFTWARES = ["WhatsApp", "Phone", "Swiggy", "Zomato", "Dunzo", "Dukaan", "Create New"];

export const DEMO_GIVEN_PERSON = ["Owner", "Owner's relative", "Staff"];
export const MILLIS_IN_DAY = 86400000;
