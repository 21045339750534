import React from "react";
import * as S from "./style";
import { formatTime, time } from "../../utils/global-functions";

const FollowupsRows = ({ item, idx, stores }) => {
    return (
        <S.TableRow key={idx}>
            <S.TableData flex="0.5" $center>
                {idx + 1}.
            </S.TableData>
            {/* <S.TableData>{formatTime(item?.date_created)}</S.TableData> */}
            <S.TableData>{stores[item?.store_id]?.name}</S.TableData>
            <S.TableData flex="3">{stores[item?.store_id]?.address}</S.TableData>
            <S.TableData>{time(item?.date)}</S.TableData>
        </S.TableRow>
    );
};

export default FollowupsRows;
