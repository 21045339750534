import React, { useState } from "react";
import { Avatar, Box, Container, InputBase, alpha, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import * as S from "./style";
import { useNavigate } from "react-router-dom";
import BackButton from "../../assets/images/back-arrow-btn.svg";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    border: "1px solid #253238",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: 0,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
}));
const SearchBox = ({ data = [], setOpenSearch }) => {
    const [options, setOptions] = useState(data);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        let { value } = e.target;
        if (value === "") {
            setOptions(data);
        }
        let s1 = data.filter((a) => a.name.toLowerCase() === value.toLowerCase());
        let s2 = data.filter((a) => a.name.toLowerCase().startsWith(value.toLowerCase()));
        let s3 = data.filter((a) => a.name.toLowerCase().includes(value.toLowerCase()));
        let final = [...new Set([...s1, ...s2, ...s3])];
        final.push({ name: "Add new store", id: "" });
        setOptions(final);
    };

    const handleNavigation = (route) => {
        navigate(`/form/create/${route}`);
    };

    const handleListClick = (item) => {
        if (item.id) {
            handleNavigation(item.id);
        } else {
            handleNavigation("new-store");
        }
    };

    const handleBack = () => {
        setOpenSearch((pre) => !pre);
    };

    return (
        <Box>
            <Container sx={{ display: "flex", alignItems: "center", gap: "4px", p: 0 }}>
                <Avatar variant="square" alt="Back button" src={BackButton} onClick={handleBack} />
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase placeholder="Search…" inputProps={{ "aria-label": "search" }} autoFocus onChange={handleInputChange} />
                </Search>
            </Container>
            <Container
                sx={{
                    maxHeight: "calc(100vh - 190px)",
                    overflow: "scroll",
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    mt: 1,
                    background: "#f9f9f9",
                    borderRadius: "3px",
                }}
            >
                {options?.map((item, idx) => (
                    <S.Li key={idx} id={item.id} onClick={() => handleListClick(item)}>
                        {item.name}
                    </S.Li>
                ))}
            </Container>
        </Box>
    );
};

export default SearchBox;
