import React from "react";
import * as S from "./style";
import FollowupsRows from "./FollowupsRows";

const FollowupsTable = ({ data, stores }) => {
    if (!data?.length) {
        return <S.Title>No follow ups today</S.Title>;
    }
    return (
        <div>
            <S.TableHeadRow>
                <S.TableHead flex="0.5" $center>
                    S.N.
                </S.TableHead>
                {/* <S.TableHead>Created</S.TableHead> */}
                <S.TableHead>Store</S.TableHead>
                <S.TableHead flex="3">Address</S.TableHead>
                <S.TableHead>When</S.TableHead>
            </S.TableHeadRow>
            <S.TableBody>
                {data?.map((item, idx) => (
                    <>
                        <FollowupsRows key={idx} item={item} idx={idx} stores={stores} />
                        <S.Divider></S.Divider>
                    </>
                ))}
            </S.TableBody>
        </div>
    );
};

export default FollowupsTable;
