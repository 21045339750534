import { Box, Button, Container, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDropDown from "../../components/custom-dropdown/CustomDropDown";
import Label from "../../components/custom-label/Label";
import BlueButton from "../../components/blue-button/BlueButton";
import { useNavigate, useParams } from "react-router-dom";
import { EXISTING_SOFTWARES, ONLINE_SOFTWARES, boolean_values } from "../../constants/constants";
import { db } from "../../firebase";
import { doc, getDoc, collection, addDoc, setDoc, GeoPoint, query, orderBy, getDocs, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { errorToast, successToast } from "../../utils/toast";
import { LoaderContext } from "../../context/LoaderContext";
import { nanoid } from "nanoid";
import { UserAuth } from "../../context/AuthContext";
import CustomRadioGroup from "../../components/custom-radio/CustomRadioGroup";
import { formatTime } from "../../utils/global-functions";
import imageCompression from "browser-image-compression";

const initialvalue = {
    name: "",
    phone: "",
    email: "",
    image: "",
    address: "",
    is_existing_software: null,
    existing_software: "",
    employee_count: "",
    sq_feet: "",
    is_online: null,
    online_software: "",
    other_online_software: "",
    no_of_existing_software: "",
    existing_software_problem: "",
    other_existing_software: "",
};
var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};
const initialVisitor = {
    name: "",
    time: "0",
};
const StoreForm = () => {
    const navigate = useNavigate();
    const [storeData, setStoreData] = useState(initialvalue);
    const [imageUrl, setImageUrl] = useState(null);
    const [isStorePresent, setIsStorePresent] = useState(false);
    const { store } = useParams();
    const { userData, user } = UserAuth();
    const { setShowLoader } = React.useContext(LoaderContext);
    const [visitor, setVisitor] = useState(initialVisitor);

    useEffect(() => {
        handleGetStoredata();
        handleVisitorData();
    }, []);

    const handleVisit = () => {
        // if (!storeData.image) {
        //     alert("Please upload the store image");
        //     return;
        // }
        let proceed = checkData();
        if (proceed) {
            setShowLoader(true);
            handleGeoLocations();
        }
    };

    const handleNavigate = (store_id) => {
        navigate(`/form/visit/${store_id}`);
    };

    const handleGetStoredata = async () => {
        const docRef = doc(db, "stores", store);
        try {
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setStoreData({ ...docSnap.data() });
                if (docSnap.data().image) {
                    setImageUrl(docSnap.data().image);
                }
                setIsStorePresent(true);
            } else {
                // docSnap.data() will be undefined in this case
                console.log("No such document!");
                setIsStorePresent(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleFileUpload = async (event) => {
        try {
            const file = event.target.files[0];

            // Check if a file was selected
            if (!file) {
                console.error("No file selected");
                return;
            }

            // Read the selected file as a data URL
            const imageUrl = await readFileAsDataURL(file);

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };

            var compressedFile;
            try {
                // Compress the image before uploading (assuming you have the 'imageCompression' function)
                compressedFile = await imageCompression(file, options);
            } catch (e) {
                console.log(e);
            } finally {
                // Upload the compressed file to Firestore (assuming you have the 'handleUploadFileToFirestore' function)
                await handleUploadFileToFireStore(compressedFile ?? file);
            }

            // Now you can set the image URL to display the uploaded image
            setImageUrl(imageUrl);
        } catch (error) {
            console.error("Error handling file upload:", error);
        }
    };

    const handleUploadFileToFireStore = async (image) => {
        // Get a reference to the storage service
        const storage = getStorage();

        // Create a storage reference
        let id = store === "new-store" ? nanoid() : store;
        const storageRef = ref(storage, `stores/${id}.png`);

        // Get the file from an input element or any source
        const file = image;

        // Upload the file
        setShowLoader(true);
        try {
            let snapshot = await uploadBytes(storageRef, file);
            let url = await getDownloadURL(snapshot.ref);
            setStoreData({ ...storeData, image: url });
        } catch (err) {
            console.log(err);
        } finally {
            setShowLoader(false);
        }
    };

    const handleInputChange = (event) => {
        let { value, name } = event.target;
        if (name === "phone" && value.length > 10) {
            return;
        }
        setStoreData({ ...storeData, [name]: value });
    };

    const handleSelectChange = (value, name) => {
        setStoreData({ ...storeData, [name]: value });
    };
    const handleRadioChange = (value, name) => {
        setStoreData({ ...storeData, [name]: value });
    };

    function success(pos) {
        var crd = pos.coords;
        handleUploadDocs(crd);
    }

    function errors(err) {
        setShowLoader(false);
        alert("Unable to get location");
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    const handleGeoLocations = () => {
        if (navigator.geolocation) {
            navigator.permissions.query({ name: "geolocation" }).then(function (result) {
                if (result.state === "granted") {
                    navigator.geolocation.getCurrentPosition(success);
                    // If granted then you can directly call your function here
                } else if (result.state === "prompt") {
                    navigator.geolocation.getCurrentPosition(success, errors, options);
                } else if (result.state === "denied") {
                    setShowLoader(false);
                    // If denied then you have to show instructions to enable location
                    alert("Please allow location permission");
                }
                result.onchange = function () {
                    // console.log(result.state);
                };
            });
        } else {
            setShowLoader(false);
            alert("Sorry Not available!");
        }
    };

    const handleUploadDocs = (crd) => {
        if (isStorePresent) {
            handleSetDoc(crd);
        } else {
            handleAddDoc(crd);
        }
    };

    const checkData = () => {
        let {
            name,
            phone,
            is_online,
            online_software,
            is_existing_software,
            existing_software,
            no_of_existing_software,
            existing_software_problem,
            image,
            address,
        } = storeData;
        if (!name) {
            errorToast("Please enter name");
            return false;
        }
        if (!address) {
            errorToast("Please enter address");
            return false;
        }
        if (!phone) {
            errorToast("Please enter phone number");
            return false;
        }
        if (is_online === null) {
            errorToast("Please choose online software present or not");
            return false;
        }
        if (is_online && !online_software) {
            errorToast("Please choose online software name");
            return false;
        }
        if (is_existing_software === null) {
            errorToast("Please choose existing software present or not");
            return false;
        }
        if (is_existing_software && !existing_software) {
            errorToast("Please choose existing software name");
            return false;
        }
        if (is_existing_software && !no_of_existing_software) {
            errorToast("Please enter no of existing software");
            return false;
        }
        if (is_existing_software && !existing_software_problem) {
            errorToast("Please enter existing software problems");
            return false;
        }
        if (!image) {
            errorToast("Please upload store image");
            return false;
        }
        return true;
    };

    const handleAddDoc = async (crd) => {
        // Define the collection and document data
        const storeCollection = collection(db, "stores");
        const payload = {
            ...storeData,
            user_id: user.uid,
            lat_lng: new GeoPoint(crd.latitude, crd.longitude),
            area_code: userData.default_area_code,
            date_created: Date.now(),
        };

        if (storeData.existing_software === "Create New") payload.existing_software = storeData.other_existing_software;
        if (storeData.online_software === "Create New") payload.online_software = storeData.other_online_software;

        // Add the document to the collection
        try {
            const newDocRef = await addDoc(storeCollection, payload);
            handleNavigate(newDocRef.id);
        } catch (e) {
            console.log(e);
        } finally {
            setShowLoader(false);
        }
    };

    const handleSetDoc = async (crd) => {
        // Define the collection and document data
        const storeCollection = collection(db, "stores");
        const payload = {
            ...storeData,
            date_updated: Date.now(),
        };
        if (storeData.existing_software === "Create New") payload.existing_software = storeData.other_existing_software;
        if (storeData.online_software === "Create New") payload.online_software = storeData.other_online_software;

        // Define the document reference
        const docRef = doc(storeCollection, store);
        try {
            // Add or update the document
            await setDoc(docRef, payload);

            // Log a success message
            handleNavigate(store);
        } catch (e) {
            console.log(e);
        } finally {
            setShowLoader(false);
        }
    };

    const handleVisitorData = async () => {
        if (store === "new-store") return;
        let q = query(collection(db, "visits"), where("store_id", "==", store), orderBy("date_created", "desc"));
        try {
            const querySnapshot = await getDocs(q);

            let fetchedData = [];
            querySnapshot.forEach((doc) => {
                fetchedData.push(doc.data());
            });

            let v = fetchedData[0];
            if (v) {
                handleGetVisitorInfo(v);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleGetVisitorInfo = async (v) => {
        const docRef = doc(db, "users", v.user_id);
        try {
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setVisitor({ ...visitor, name: docSnap.data().name, time: formatTime(v.date_created) });
            } else {
                // docSnap.data() will be undefined in this case
                console.log("No such document!");
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <Container maxWidth="xs" sx={{ marginTop: "60px", overflow: "scroll", height: "calc(100vh - 116px)" }}>
                {isStorePresent && visitor.name && (
                    <Box
                        sx={{
                            background: "#2e3bf3",
                            opacity: 0.5,
                            p: 1,
                            mt: 2,
                            mb: 1,
                            color: "#fff",
                            textAlign: "center",
                            borderRadius: "12px",
                            fontSize: "12px",
                        }}
                    >
                        Last visited by {visitor?.name} at {visitor?.time}
                    </Box>
                )}
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Store Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={storeData.name}
                    onChange={handleInputChange}
                />
                <TextField
                    type="number"
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="Phone"
                    name="phone"
                    autoComplete="phone"
                    value={storeData.phone}
                    onChange={handleInputChange}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={storeData.email}
                    onChange={handleInputChange}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    required
                    id="address"
                    label="Address"
                    name="address"
                    autoComplete="address"
                    value={storeData.address}
                    onChange={handleInputChange}
                />
                <CustomRadioGroup
                    label="Existing Software *"
                    name="is_existing_software"
                    data={boolean_values}
                    id="is_existing_software"
                    onChange={handleRadioChange}
                    givenValue={storeData?.is_existing_software}
                />

                {storeData.is_existing_software && (
                    <>
                        <Label text="Existing Software Name" htmlFor="existing_software" />
                        <CustomDropDown
                            data={EXISTING_SOFTWARES}
                            name={"existing_software"}
                            id="existing_software"
                            onChange={handleSelectChange}
                            givenValue={storeData?.existing_software}
                        />
                        {storeData?.existing_software === "Create New" && (
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                id="other_existing_software"
                                label="Other Existing Software"
                                name="other_existing_software"
                                autoComplete="other_existing_software"
                                value={storeData.other_existing_software}
                                onChange={handleInputChange}
                            />
                        )}
                        <TextField
                            type="number"
                            margin="normal"
                            fullWidth
                            required
                            id="no_of_existing_software"
                            label="No Of Existing Software"
                            name="no_of_existing_software"
                            autoComplete="no_of_existing_software"
                            value={storeData.no_of_existing_software}
                            onChange={handleInputChange}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            id="existing_software_problem"
                            label="Problems Existing Software"
                            name="existing_software_problem"
                            autoComplete="existing_software_problem"
                            value={storeData.existing_software_problem}
                            onChange={handleInputChange}
                        />
                    </>
                )}
                <TextField
                    type="number"
                    margin="normal"
                    fullWidth
                    id="employee_count"
                    label="Employee Count"
                    name="employee_count"
                    autoComplete="employee_count"
                    value={storeData.employee_count}
                    onChange={handleInputChange}
                />
                <TextField
                    type="number"
                    margin="normal"
                    fullWidth
                    id="sq_feet"
                    label="Square Feet"
                    name="sq_feet"
                    autoComplete="sq_feet"
                    value={storeData.sq_feet}
                    onChange={handleInputChange}
                />

                <CustomRadioGroup
                    label="Online Order *"
                    name="is_online"
                    data={boolean_values}
                    id="is_online"
                    onChange={handleRadioChange}
                    givenValue={storeData?.is_online}
                />

                {storeData.is_online && (
                    <>
                        <Label text="Online Software Name" htmlFor="online_software" />
                        <CustomDropDown
                            data={ONLINE_SOFTWARES}
                            name={"online_software"}
                            id="online_software"
                            onChange={handleSelectChange}
                            givenValue={storeData?.online_software}
                        />

                        {storeData?.online_software === "Create New" && (
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                id="other_online_software"
                                label="Other Online Software"
                                name="other_online_software"
                                autoComplete="other_online_software"
                                value={storeData.other_online_software}
                                onChange={handleInputChange}
                            />
                        )}
                    </>
                )}

                <Stack direction="column" spacing={2} mt={2}>
                    {imageUrl && <img src={imageUrl} alt="Uploaded" height="100" width="100" />}
                    <label htmlFor="upload-image">
                        <Button
                            variant="contained"
                            component="span"
                            sx={{
                                textTransform: "none",
                                backgroundColor: "#2E65F3",
                                "&:hover": {
                                    backgroundColor: "#2979ff",
                                },
                            }}
                        >
                            Upload Store Image *
                        </Button>
                        <input id="upload-image" hidden accept="image/*" type="file" onChange={handleFileUpload} />
                    </label>
                </Stack>
            </Container>
            <Container
                maxWidth="xs"
                sx={{ bottom: 0, background: "#fff", zIndex: 10, display: "flex", flexDirection: "column", alignItems: "center" }}
            >
                <BlueButton text="Visit" onClick={handleVisit} />
            </Container>
        </>
    );
};

export default StoreForm;
