import { useContext, createContext, useEffect, useState } from "react";
import { signOut, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { errorToast } from "../utils/toast";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();

    const userLoginWithEmail = (payload) => {
        let { email, password } = payload;
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                navigate("/form/create");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log("error", error);
                errorToast("Invalid email or password");
            });
    };

    const logOut = () => {
        signOut(auth)
            .then(() => {
                // Sign-out successful.
            })
            .catch((error) => {
                // An error happened.
            });
    };

    const handleGetUserdata = (id, cb) => {
        const docRef = doc(db, "users", id);
        return onSnapshot(docRef, (doc) => {
            setUserData(doc.data());
            cb();
        });
    };

    const xyz = (currentUser) => {
        setUser(currentUser);
        navigate("/form/create");
    };

    useEffect(() => {
        let u1;
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser === null) {
                setUser(currentUser);
                navigate("/");
            } else {
                let user_id = currentUser.uid;
                u1 = handleGetUserdata(user_id, () => {
                    xyz(currentUser);
                });
            }
        });

        return () => {
            unsubscribe();
            if (u1) {
                u1();
            }
        };
    }, []);

    return <AuthContext.Provider value={{ userLoginWithEmail, logOut, user, userData }}>{children}</AuthContext.Provider>;
};

export const UserAuth = () => {
    return useContext(AuthContext);
};
