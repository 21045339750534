import { Box, Button, Container, Stack, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import CustomRadioGroup from "../../components/custom-radio/CustomRadioGroup";
import { DEMO_GIVEN_PERSON, boolean_values, follow_up_type, ratings } from "../../constants/constants";
import BlueButton from "../../components/blue-button/BlueButton";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { GeoPoint, addDoc, collection } from "firebase/firestore";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Label from "../../components/custom-label/Label";
import { LoaderContext } from "../../context/LoaderContext";
import { UserAuth } from "../../context/AuthContext";
import { isUndefined } from "../../utils/global-functions";
import { errorToast, successToast } from "../../utils/toast";
import imageCompression from "browser-image-compression";
import CustomDropDown from "../../components/custom-dropdown/CustomDropDown";

const initialVisitData = {
    overall_likability: null,
    mobile_pos_likability: null,
    desktop_pos_likability: null,
    is_demo_given: null,
    next_follow_up: null,
    how: "",
    date: null,
    store_comment: "",
    selfie: null,
};
const Visit = () => {
    const [visitData, setVisitData] = useState({});
    const [imageUrl, setImageUrl] = useState(null);
    const { store } = useParams();
    const navigate = useNavigate();
    const { setShowLoader } = useContext(LoaderContext);
    const { userData, user } = UserAuth();

    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleFileUpload = async (event) => {
        setShowLoader(true);

        try {
            const file = event.target.files[0];

            // Check if a file was selected
            if (!file) {
                console.error("No file selected");
                return;
            }

            // Read the selected file as a data URL
            const imageUrl = await readFileAsDataURL(file);

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };

            var compressedFile;
            try {
                // Compress the image before uploading (assuming you have the 'imageCompression' function)
                compressedFile = await imageCompression(file, options);
            } catch (e) {
                console.log(e);
            } finally {
                // Upload the compressed file to Firestore (assuming you have the 'handleUploadFileToFirestore' function)
                await handleUploadFileToFireStore(compressedFile ?? file);
            }

            // Now you can set the image URL to display the uploaded image
            setImageUrl(imageUrl);
        } catch (error) {
            console.error("Error handling file upload:", error);
        } finally {
            setShowLoader(false);
        }
    };

    const handleUploadFileToFireStore = async (image) => {
        // Get a reference to the storage service
        const storage = getStorage();

        // Create a storage reference
        const storageRef = ref(storage, `visits/${store}-${Date.now()}.png`);

        // Get the file from an input element or any source
        const file = image;

        // Upload the file
        try {
            let snapshot = await uploadBytes(storageRef, file);
            let url = await getDownloadURL(snapshot.ref);
            setVisitData({ ...visitData, selfie: url });
        } catch (err) {
            console.log("Error uploading file", err);
        }
    };

    const handleRadioChange = (value, name) => {
        setVisitData({ ...visitData, [name]: value });
    };

    const handleInputChange = (event) => {
        let { name, value } = event.target;
        setVisitData({ ...visitData, [name]: value });
    };

    const handleSelectChange = (value, name) => {
        setVisitData({ ...visitData, [name]: value });
    };

    const handleAddVisitData = async (crd) => {
        let {
            overall_likability,
            mobile_pos_likability,
            desktop_pos_likability,
            is_demo_given,
            next_follow_up,
            how,
            date,
            store_comment,
            is_desktop_pos_installed,
            is_mobile_pos_installed,
            is_paid,
            selfie,
            demo_given_to,
        } = visitData;
        if (
            isUndefined(overall_likability) ||
            isUndefined(mobile_pos_likability) ||
            isUndefined(desktop_pos_likability) ||
            isUndefined(next_follow_up) ||
            isUndefined(is_demo_given) ||
            isUndefined(store_comment) ||
            isUndefined(is_desktop_pos_installed) ||
            isUndefined(is_mobile_pos_installed) ||
            isUndefined(selfie)
        ) {
            errorToast("Please fill all the fields");
            return;
        }

        if (next_follow_up && isUndefined(how) && isUndefined(date)) {
            errorToast("Please fill how you want next follow up");
            return;
        }

        if ((is_desktop_pos_installed || is_mobile_pos_installed) && isUndefined(is_paid)) {
            errorToast("Please fill payment details");
            return;
        }
        if (is_demo_given && (isUndefined(demo_given_to) || !demo_given_to)) {
            errorToast("Please choose who received the demo");
            return;
        }
        setShowLoader(true);

        // Define the collection and document data
        const visitCollection = collection(db, "visits");
        const payload = {
            ...visitData,
            store_id: store,
            user_id: user.uid,
            area_code: userData.default_area_code,
            lat_lng: new GeoPoint(crd.latitude, crd.longitude),
            date_created: Date.now(),
        };

        try {
            await addDoc(visitCollection, payload);
            successToast("Store visit completed");
            navigate("/form/create");
        } catch (e) {
            console.log(e);
        } finally {
            setShowLoader(false);
        }
        // Add the document to the collection
    };

    const handleDateChange = (value) => {
        setVisitData({ ...visitData, date: value.unix() * 1000 });
    };

    function success(pos) {
        var crd = pos.coords;
        handleAddVisitData(crd);
    }

    function errors(err) {
        alert("Unable to get location");
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    const handleSave = () => {
        navigator.geolocation.getCurrentPosition(success);
    };

    return (
        <>
            <Container
                maxWidth="xs"
                sx={{
                    marginTop: "80px",
                    overflow: "scroll",
                    height: "calc(100vh - 134px)",
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    paddingTop: "12px",
                }}
            >
                <CustomRadioGroup
                    label="Overall Likability?"
                    name="overall_likability"
                    data={ratings}
                    id="overall_likability"
                    onChange={handleRadioChange}
                    required={true}
                />
                <CustomRadioGroup
                    label="Mobile POS Likability?"
                    name="mobile_pos_likability"
                    data={ratings}
                    id="mobile_pos_likability"
                    onChange={handleRadioChange}
                    required={true}
                />
                <CustomRadioGroup
                    label="Desktop POS Likability?"
                    name="desktop_pos_likability"
                    data={ratings}
                    id="desktop_pos_likability"
                    onChange={handleRadioChange}
                    required={true}
                />
                <CustomRadioGroup
                    required={true}
                    label="Is Demo Given?"
                    name="is_demo_given"
                    data={boolean_values}
                    id="is_demo_given"
                    onChange={handleRadioChange}
                />
                {visitData.is_demo_given === true && (
                    <>
                        <Label text="Demo Given To" htmlFor="demo_given_to" />
                        <CustomDropDown data={DEMO_GIVEN_PERSON} name={"demo_given_to"} id="demo_given_to" onChange={handleSelectChange} />

                        <TextField
                            margin="normal"
                            fullWidth
                            id="demo_receiver_name"
                            label="Name"
                            name="demo_receiver_name"
                            autoComplete="demo_receiver_name"
                            onChange={handleInputChange}
                        />
                    </>
                )}
                <CustomRadioGroup
                    label="Next Follow Up?"
                    name="next_follow_up"
                    data={boolean_values}
                    id="next_follow_up"
                    onChange={handleRadioChange}
                    required={true}
                />
                {visitData.next_follow_up === true && (
                    <>
                        <CustomRadioGroup required={true} label="How?" name="how" data={follow_up_type} id="how" onChange={handleRadioChange} />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Label text="Date & Time *" htmlFor="" />
                            <MobileDateTimePicker onChange={(newValue) => handleDateChange(newValue)} format="" />
                        </LocalizationProvider>
                    </>
                )}
                <TextField
                    margin="normal"
                    fullWidth
                    id="store_comment"
                    label="Store Comments"
                    name="store_comment"
                    autoComplete="store_comment"
                    onChange={handleInputChange}
                    required
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="store_requirement"
                    label="Store Requirement"
                    name="store_requirement"
                    autoComplete="store_requirement"
                    onChange={handleInputChange}
                />
                <CustomRadioGroup
                    label="Is Desktop POS Installed?"
                    name="is_desktop_pos_installed"
                    data={boolean_values}
                    id="is_desktop_pos_installed"
                    onChange={handleRadioChange}
                    required={true}
                />
                <CustomRadioGroup
                    label="Is Mobile POS Installed?"
                    name="is_mobile_pos_installed"
                    data={boolean_values}
                    id="is_mobile_pos_installed"
                    onChange={handleRadioChange}
                    required={true}
                />
                {(visitData.is_desktop_pos_installed === true || visitData.is_mobile_pos_installed === true) && (
                    <CustomRadioGroup
                        label="Is Paid??"
                        name="is_paid"
                        data={boolean_values}
                        id="is_paid"
                        onChange={handleRadioChange}
                        required={true}
                    />
                )}
                <Stack direction="column" spacing={2} mt={2}>
                    {imageUrl && <img src={imageUrl} alt="Uploaded" height="100" width="100" />}
                    <label htmlFor="upload-image">
                        <Button
                            variant="contained"
                            component="span"
                            sx={{
                                textTransform: "none",
                                backgroundColor: "#2E65F3",
                                "&:hover": {
                                    backgroundColor: "#2979ff",
                                },
                            }}
                        >
                            Upload Selfie *
                        </Button>
                        <input id="upload-image" hidden accept="image/*" type="file" onChange={handleFileUpload} />
                    </label>
                </Stack>
            </Container>
            <Container
                maxWidth="xs"
                sx={{
                    bottom: 0,
                    background: "#fff",
                    zIndex: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 2,
                    mb: 2,
                }}
            >
                <BlueButton text="Save" onClick={handleSave} />
            </Container>
        </>
    );
};

export default Visit;
