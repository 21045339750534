import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    margin: 12px 0;
`;

export const Title = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.64);
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    padding: 8px;
    margin-bottom: 12px;
`;
