import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { db } from "../../firebase";
import { UserAuth } from "../../context/AuthContext";
import SearchBox from "../../components/search-box/SearchBox";
import { errorToast } from "../../utils/toast";
import Followups from "../../components/followups/Followups";

const Form = () => {
    const [stores, setStores] = useState([]);
    const { userData } = UserAuth();
    const [openSearch, setOpenSearch] = useState(false);
    const [queryArray, setQueryArray] = useState([]);

    useEffect(() => {
        handleGetStoresData();
    }, [queryArray]);

    useEffect(() => {
        if (userData) {
            if (userData?.default_area_code) {
                setQueryArray([where("area_code", "==", userData?.default_area_code)]);
            }
        }
    }, [userData.default_area_code]);

    const handleGetStoresData = async () => {
        let q = query(collection(db, "stores"), orderBy("name", "asc"), ...queryArray);
        try {
            const querySnapshot = await getDocs(q);

            let fetchedStores = [];
            querySnapshot.forEach((doc) => {
                fetchedStores.push({ name: doc.data().name, id: doc.id, code: doc.data().area_code });
            });
            setStores(fetchedStores);
        } catch (err) {
            console.log(err);
        }
    };

    const handleToggleOpenSearch = () => {
        if (userData.default_area_code) {
            setOpenSearch(true);
        } else {
            errorToast("Please select your area code");
        }
    };

    return (
        <Container maxWidth="xs" sx={{ marginTop: "80px", paddingBottom: "16px" }}>
            {openSearch ? (
                <SearchBox data={stores} setOpenSearch={setOpenSearch} />
            ) : (
                <Box>
                    {userData.default_area_code ? (
                        <>
                            <Box sx={{ background: "green", p: 1, color: "#fff", textAlign: "center" }}>
                                Your default area code is set {userData.default_area_code}.
                            </Box>
                            <Followups />
                        </>
                    ) : (
                        <Box sx={{ background: "red", p: 1, color: "#fff", textAlign: "center" }}>Please set your area code.</Box>
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Typography component="h1" variant="h5" sx={{ m: "12px" }} gutterBottom>
                            STORE
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                width: "100%",
                                gap: "10px",
                            }}
                        >
                            <TextField label="Search Store..." sx={{ cursor: "text", width: "100%" }} onClick={handleToggleOpenSearch} />
                        </Box>
                    </Box>
                </Box>
            )}
        </Container>
    );
};

export default Form;
