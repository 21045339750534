import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import HomeDropLogo from "../../assets/images/homedrop-logo.svg";
import { UserAuth } from "../../context/AuthContext";
import { LoaderContext } from "../../context/LoaderContext";
import { errorToast } from "../../utils/toast";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {"Copyright © "}
            <Link color="inherit" href="https://homedrop.in/" target="_blank">
                HomeDrop
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const defaultTheme = createTheme();

export default function SignIn() {
    const { userLoginWithEmail } = UserAuth();
    const { setShowLoader } = React.useContext(LoaderContext);

    const handleSignIn = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        setShowLoader(true);
        try {
            let res = await userLoginWithEmail({
                email: data.get("email").trim(),
                password: data.get("password").trim(),
            });
        } catch (err) {
            console.log("err", err);
        } finally {
            setShowLoader(false);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar alt="HomeDrop Logo" src={HomeDropLogo} sx={{ m: 1 }} />
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSignIn} noValidate sx={{ mt: 1 }}>
                        <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: "#2E65F3",
                                "&:hover": {
                                    backgroundColor: "#2979ff",
                                },
                            }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
