import moment from "moment";

export const isUndefined = (value) => {
    if (value === undefined) return true;
    else return false;
};

export const formatTime = (unix) => {
    return moment(unix).format("lll");
};

export const time = (unix) => {
    return moment(unix).format("LT");
};
