import { collection, getDocs, orderBy, query, where } from "@firebase/firestore";
import React, { useEffect, useState } from "react";
import * as S from "./style";
import { db } from "../../firebase";
import moment from "moment";
import { UserAuth } from "../../context/AuthContext";
import { MILLIS_IN_DAY } from "../../constants/constants";
import FollowupsTable from "../followups-table/FollowupsTable";

const today = moment().startOf("day").valueOf();
const Followups = () => {
    const [storesData, setStoresData] = useState(null);
    const [followupsData, setFollowupsData] = useState(null);
    const { userData, user } = UserAuth();

    useEffect(() => {
        handleGetAllStoresData();
    }, []);

    useEffect(() => {
        if (storesData !== null && user !== null && userData !== null) {
            handleGetFollowupVisits();
        }
    }, [storesData, user, userData]);

    const handleGetAllStoresData = async () => {
        let q = query(collection(db, "stores"), where("area_code", "==", userData?.default_area_code), orderBy("date_created", "desc"));
        let stores = [];
        try {
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                stores.push({ ...doc.data(), id: doc.id });
            });
        } catch (err) {
            console.log(err);
        }

        let formatData = {};
        for (let i = 0; i < stores.length; i++) {
            let id = stores[i].id;
            let name = stores[i].name;
            let address = stores[i].address;
            formatData[id] = {
                id,
                name,
                address,
            };
        }

        setStoresData(formatData);
    };

    const handleGetFollowupVisits = async () => {
        let q = query(
            collection(db, "visits"),
            where("area_code", "==", userData.default_area_code),
            where("user_id", "==", user.uid),
            where("date", ">=", today),
            where("date", "<", today + MILLIS_IN_DAY),
            orderBy("date", "asc")
        );
        let fetchedVisits = [];
        try {
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                fetchedVisits.push({ ...doc.data(), id: doc.id });
            });
        } catch (err) {
            console.log(err);
        }
        setFollowupsData(fetchedVisits);
    };
    return (
        <S.Wrapper>
            <S.Title>Today's Follow Ups</S.Title>
            <FollowupsTable data={followupsData} stores={storesData} />
        </S.Wrapper>
    );
};

export default Followups;
