import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function CustomRadioGroup({ label, name, onChange, data, id = "id", givenValue = null, required = false }) {
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (givenValue !== null) {
            setValue(givenValue);
        }
    }, [givenValue]);

    const handleChange = (event) => {
        let { value } = event.target;
        setValue(value);
        if (onChange) onChange(value === "true" ? true : value === "false" ? false : value, name);
    };

    return (
        <FormControl>
            <FormLabel required={required} id={id}>
                {label}
            </FormLabel>
            <RadioGroup name={name} value={value} onChange={handleChange} row>
                {data.map((item) => (
                    <FormControlLabel value={item.value} control={<Radio />} label={item.name} key={item.value} />
                ))}
            </RadioGroup>
        </FormControl>
    );
}
